import axios from '@/libs/axios'

const endpoint = 'settings/catalog/defaults'
const updateDefaultSettings = (ctx, data) => axios.post(endpoint, data)
const getDefaultSettings = ({ commit }) => new Promise((resolve, reject) => {
  axios.get(endpoint)
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export default {
  getDefaultSettings,
  updateDefaultSettings,
}
