import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import {stringify} from "qs";

const endpoint = () => 'kits'
const Actions = crudActions(endpoint())


const getActiveKitsList = ({commit}, queryParams) => new Promise((resolve, reject) => {
    queryParams = {...queryParams, ...{is_active: 1}}
    axios.get(endpoint(), {
        params: queryParams,
        paramsSerializer: params => stringify(params),
    })
        .then(response => {
            commit('LIST', response.data.data.data)
            resolve(response)
        })
        .catch(error => {
            console.log(error)
            reject(error)
        })
})
const getInActiveKitsList = ({commit}, queryParams) => new Promise((resolve, reject) => {
    queryParams = {...queryParams, ...{is_active: 0}}
    axios.get(endpoint(), {
        params: queryParams,
        paramsSerializer: params => stringify(params),
    })
        .then(response => {
            commit('LIST', response.data.data.data)
            resolve(response)
        })
        .catch(error => {
            console.log(error)
            reject(error)
        })
})

const updateKits = (ctx, data) => axios.put(`/${endpoint}/${data.id}`, data)

const setActive = (ctx, data) => new Promise((resolve, reject) => {
    data.is_active = 'true';
    delete data.products
    axios.put(`/${endpoint()}/${data.id}`, data)
        .then(response => {
            resolve(response)
        }).catch(error => {
        console.log(error)
        reject(error)
    })
})
const setInActive = (ctx, data) => new Promise((resolve, reject) => {
    data.is_active = 'false';
    delete data.products
    axios.put(`/${endpoint()}/${data.id}`, data)
        .then(response => {
            resolve(response)
        }).catch(error => {
        console.log(error)
        reject(error)
    })
})

const getProducts = ({commit}, queryParams) => new Promise((resolve, reject) => {
    axios.get('/products', {params: queryParams})
        .then(response => {
            commit('LIST', response.data.data.data)
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export default {
    ...Actions,
    getProducts,
    getActiveKitsList,
    getInActiveKitsList,
    updateKits,
    setActive,
    setInActive,
}
