export default {
  GET(state, data) {
    state.kit = { ...data }
  },
  LIST(state, data) {
    state.kits = data
  },
  SET_FORM(state, data) {
    state.kitForm = { ...data }
  },
}
