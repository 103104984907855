export default {
  GET(state, data) {
    state.catalogDefault = { ...data }
  },
  LIST(state, data) {
    state.catalogDefaults = data
  },
  SET_FORM(state, data) {
    state.catalogDefaultForm = { ...data }
  },
}
