<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import catalogModule from '@/store/main/catalog'
import settingsCatalogModule from '@/store/settings/catalog/defaults'
import kitsModule from '@/store/main/catalog/kits'

export default {
  name: 'Catalog',
  provide: {
    catalogModuleName: 'catalogs',
    kitsModuleName: 'kits',
  },
  components: {
  },
  setup() {
    const MODULE_NAME = 'catalogs'
    const KITS_MODULE_NAME = 'kits'
    const SETTINGS_CATALOG_MODULE_NAME = 'settings-catalog-defaults'

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, catalogModule)
    if (!store.hasModule(KITS_MODULE_NAME)) store.registerModule(KITS_MODULE_NAME, kitsModule)
    if (!store.hasModule(SETTINGS_CATALOG_MODULE_NAME)) store.registerModule(SETTINGS_CATALOG_MODULE_NAME, settingsCatalogModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
      if (store.hasModule(KITS_MODULE_NAME)) store.unregisterModule(KITS_MODULE_NAME)
      if (store.hasModule(SETTINGS_CATALOG_MODULE_NAME)) store.unregisterModule(SETTINGS_CATALOG_MODULE_NAME)
    })
    return {
      MODULE_NAME,
      KITS_MODULE_NAME,
    }
  },
}
</script>
